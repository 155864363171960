import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import "./styles/project.css";

const Project = ({project}) => {
	const { logo, title, description, links } = project;
	return (
		<React.Fragment>
			<div className="project">
				<div className="project-container">
					<div className="project-logo">
						<img src={logo} alt="logo" />
					</div>
					<div className="project-title">{title}</div>
					<div className="project-description">{description}</div>
					{links.map(({ link, linkText }) => (
						<div className="project-link">
							<div className="project-link-icon">
								<FontAwesomeIcon icon={faLink} />
							</div>
							<Link to={link} target="_blank">
								<div className="project-link-text">
									{linkText}
								</div>
							</Link>
						</div>
					))}
				</div>
			</div>
		</React.Fragment>
	);
};

export default Project;
