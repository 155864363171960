import React from "react";
import { Helmet } from "react-helmet";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

import Logo from "../../components/common/logo";
import Footer from "../../components/common/footer";
import NavBar from "../../components/common/navBar";
import Article from "../../components/articles/article";

import INFO from "../../data/user";
import SEO from "../../data/seo";
import myArticles from "../../data/articles";

import "./homepage.css";

const Homepage = () => {
	const currentSEO = SEO.find((item) => item.page === "home");

	return (
		<React.Fragment>
			<Helmet>
				<title>{INFO.main.title}</title>
				<meta name="description" content={currentSEO?.description} />
				<meta
					name="keywords"
					content={currentSEO?.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<div>
					<NavBar active="home" />
					<div className="content-wrapper">
						<div className="homepage-logo-container">
							<Logo width={350} link={false} />
						</div>

						<div>
							<h1 className="title homepage-title">
								{
									"Atelier de développement logiciel et automatisation"
								}
							</h1>

							<div className="text">
								<div>
									<h2>Logiciel</h2>
									<p>
										Nous vous aidons à innover et améliorer
										vos procédés avec des applications web
										ou mobile faites sur mesure pour les
										besoins de votre entreprise.
									</p>
									<p>
										Nous entretenons aussi des applications
										existantes, peu importe qu'elle ait été
										développée récemment ou qu'elle date de
										plusieurs années.
									</p>
									<a href="/services-logiciel">
										{
											"En savoir plus sur nos services logiciels"
										}
									</a>
								</div>
								<div style={{ paddingTop: "24px" }}>
									<h2>Automatisation</h2>
									<p>
										Nous réalisons des projets
										d'automatisation des procédés de
										production pour des petites entreprises.
									</p>
									<p>
										Nous utilisons différentes technologies
										de prototypage pour créer des micro
										automates et les programmer sur mesure à
										plus faible coût qu'un système
										d'automatisation industriel.
									</p>
									<a href="/services-automatisation">
										{
											"En savoir plus sur nos services d'automatisation"
										}
									</a>
								</div>
							</div>
							<h2 className="title">
								{"Qui travaille à l'atelier?"}
							</h2>
							<div className="homepage-bios">
							<div className="homepage-bio">
								<div>
									<img
										src="cindy.jpg"
										alt="a-propos"
										className="homepage-image"
									/>
								</div>
								<div className="text">
									<p>
										Je m'appelle Cindy et je suis une
										développeure d'applications depuis plus
										de 15 ans.
									</p>
									<p>
										Je réalise des applications web et
										mobiles pour répondre à de petits et de
										grands besoins d'entreprise en passant
										par toutes les étapes de l'analyse de
										vos besoins jusqu'au code.
									</p>
									<p>
										Je crée aussi des circuits et des
										programmes pour l'automatisation de
										procédés à petite échelle.
									</p>
								</div>
							</div>
							<div className="homepage-bio">
								<div>
									<img
										src="dominic.jpg"
										alt="a-propos"
										className="homepage-image"
									/>
								</div>
								<div className="text">
									<p>
										Je m'appelle Dominic et je suis
										technicien en mécanique du bâtiment.
									</p>
									<p>
										J'ai oeuvré pendant plus de 15 ans dans
										le domaine des systèmes de contrôle des
										bâtiments de grande envergure.
									</p>
									<p>
										Dans les trois dernières années, j'ai
										travaillé sur de petites solutions
										d'automatisations personnalisées dans le
										milieu de la production alimentaire.
									</p>
								</div>
							</div>
							</div>
							<h2 className="title">
								{"Quelques billets sur nos réalisations..."}
							</h2>
							<div className="homepage-articles-container">
								<div className="homepage-articles">
									{myArticles.map((article, index) => (
										<div
											className="homepage-article"
											key={(index + 1).toString()}
										>
											<Article
												key={(index + 1).toString()}
												article={article}
												link={"/article/" + (index + 1)}
											/>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="page-footer">
					<Footer />
				</div>
			</div>
		</React.Fragment>
	);
};

export default Homepage;
