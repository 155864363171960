import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Markdown from "markdown-to-jsx";
import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";

import INFO from "../data/user";
import myArticles from "../data/articles";

import "./styles/readArticle.css";

let ArticleStyle = styled.div``;

const ReadArticle = () => {
	let { slug } = useParams();

	const article = myArticles[slug - 1];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [article]);

	ArticleStyle = styled.div`
		${article.style}
	`;

	const [markdown, setMarkdown] = useState();

	useEffect(() => {
		fetch(article.content)
			.then((res) => res.text())
			.then((text) => setMarkdown(text));
	}, [article.content]);

	return (
		<React.Fragment>
			<Helmet>
				<title>{`${article.title} | ${INFO.main.title}`}</title>
				<meta name="description" content={article.description} />
				<meta name="keywords" content={article.keywords.join(", ")} />
			</Helmet>

			<div className="page-content">
				<div>
					<NavBar />
					<div className="content-wrapper">
						<div className="read-article-container">
						<Logo width={350} />
								<h1 className="title">
									{article.title}
								</h1>

								<div className="read-article-body">
									<ArticleStyle>
										<div className="article-content">
											{markdown && (
												<Markdown options={{
													overrides: {
													  p: {
														props: {
														  className: 'text',
														},
													  },
													},
												  }}>{markdown}</Markdown>
											)}
										</div>
									</ArticleStyle>
								</div>
							</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ReadArticle;
