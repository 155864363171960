import React from "react";

import Project from "./project";

import "./styles/allProjects.css";

const AllProjects = ({ projects }) => {
	return (
		<div>
			<h2 className="title">Quelques-unes de nos réalisations</h2>
			<div className="all-projects-container">
				{projects.map((project, index) => (
					<div className="all-projects-project" key={index}>
						<Project project={project} />
					</div>
				))}
			</div>
		</div>
	);
};

export default AllProjects;
