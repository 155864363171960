import React from "react";
import { Link } from "react-router-dom";

import "./styles/footer.css";

const Footer = () => {
	return (
		<React.Fragment>
			<div className="footer content-wrapper">
				<div className="footer-links">
					<ul className="footer-nav-link-list">
						<li className="footer-nav-link-item">
							<Link to="/">Accueil</Link>
						</li>
						<li className="footer-nav-link-item">
							<Link to="/services-logiciel">Services logiciels</Link>
						</li>
						<li className="footer-nav-link-item">
							<Link to="/services-automatisation">Services automatisation</Link>
						</li>
						<li className="footer-nav-link-item">
							<Link to="/articles">Articles</Link>
						</li>
						<li className="footer-nav-link-item">
							<Link to="/contact">Me contacter</Link>
						</li>
					</ul>
				</div>

				<div className="footer-credits">
					<div className="footer-credits-text">
						©2024 Technologies Cindy Potvin.
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Footer;
