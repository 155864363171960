import brasseur from "./articles/brasseur.md";

const myArticles = [{
		title: "Étude de cas : Brasseur de lait pour la fromagerie La Suisse Normande",
		description:
			"Projet avec la fromagerie La Suissse Normande pour créer le circuit de contrôle d'un brasseur de lait, basé sur la plateforme Arduino.",
		keywords: [
			"étude de cas",
			"brasseur de lait",
			"Arduino",
			"électronique",
			"agtech"
		],
		content: brasseur,
}];

export default myArticles;
