import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/projects.css";

const ServicesAutomatisation = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find(
		(item) => item.page === "services-automatisation"
	);

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Projets | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO?.description} />
				<meta
					name="keywords"
					content={currentSEO?.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<div>
					<NavBar active="services-automatisation" />
					<div className="content-wrapper">
						<div className="projects-container">
							<Logo width={350} />
							<h1 className="title projects-title">
								Services d'automatisation
							</h1>
							<div className="text">
								<h2 className="title">
									Création de micro automates programmables
									sur mesure
								</h2>
								<p>
									L'automatisation de tâches répétitives en
									usine n'a pas à être extrêmement complexe et
									coûteuse. Les petites entreprises peuvent
									souvent augmenter leur productivité avec des
									solutions simples et spécifiques à leurs
									problèmes.
								</p>

								<p>
									Voici quelques types de procédés répétitifs
									qui peuvent facilement être automatisés afin
									de gagner du temps dans une petite
									entreprise :
								</p>

								<ul>
									<li>
										Remplissage récurent d'un liquide avec
										un calculateur volumétrique ajustable ou
										avec des touches prédéfinies.
										L'opérateur peut alors appuyer sur une
										touche pour démarrer le processus, sans
										autre manipulation.
									</li>

									<li>
										Système de lavage de cuve automatique
										par étape avec injections de savon,
										acide et désinfectant à la concentration
										et au temps de traitement souhaité.
										Affichage de la progression du travail
										et programmation de séquences simple.
									</li>
								</ul>
								<h3 className="title">
									Quel est le processus d'automatisation d'un
									processus?
								</h3>
								<p>
									Avant la signature du contrat, nous aurons
									une discussion pour comprendre quels sont
									vos besoins et quel est le procédé que vous
									voulez automatiser. Dans certains cas, une
									visite en usine sera aussi nécessaire pour
									voir vos installations actuelles.
								</p>
								<p>
									Par la suite, nous allons vous envoyer un
									devis pour approbation décrivant
									sommairement ce que l'automate devra faire,
									les différentes étapes pour y arriver et nos
									prix.
								</p>
								<p>Ensuite, nous allons : </p>
								<ul>
									<li>
										Faire la conception de l'automatisation
										et le choix final des différents
										composants selon le devis.
									</li>
									<li>
										Assembler les composantes et faire la
										programmation de la logique
										d'automatisation.
									</li>
									<li>
										Vous faire parvenir l'automate complété
										et prêt à être installé.
									</li>
								</ul>
								<p>
									L'installation finale sera ensuite fait par
									vos soins en usine selon nos spécifications.
									Les services d'un plombier ou d'un
									électricien pourront être nécessaires selon le
									type d'installation.
								</p>
								<p>
									Vous voulez en savoir plus sur la création
									d'un automate sur mesure pour votre
									entreprise? N'hésitez pas à{" "}
									<Link to="/contact">nous contacter</Link>{" "}
									pour discuter avec nous de ce qui est
									possible!
								</p>
							</div>
							<div className="projects-list">
								<AllProjects
									projects={INFO.projetsAutomatisation}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="page-footer">
					<Footer />
				</div>
			</div>
		</React.Fragment>
	);
};

export default ServicesAutomatisation;
