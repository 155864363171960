import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Article from "../components/articles/article";

import INFO from "../data/user";
import SEO from "../data/seo";
import myArticles from "../data/articles";

import "./styles/articles.css";
import Markdown from "markdown-to-jsx";

const Articles = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "articles");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Articles | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO?.description} />
				<meta
					name="keywords"
					content={currentSEO?.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<div>
					<NavBar active="articles" />
					<div className="content-wrapper">
						<div className="articles-main-container">
							<Logo width={350} />
							<h1 className="title articles-title">
								{INFO.articles.title}
							</h1>

							<div className="subtitle articles-subtitle">
								<Markdown>{INFO.articles.description}</Markdown>
							</div>

							<div className="articles-container">
								<div className="articles-wrapper">
									{myArticles.map((article, index) => (
										<div
											className="articles-article"
											key={(index + 1).toString()}
										>
											<Article
												key={(index + 1).toString()}
												article={article}
												link={"/article/" + (index + 1)}
											/>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="page-footer">
					<Footer />
				</div>
			</div>
		</React.Fragment>
	);
};

export default Articles;
