import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/projects.css";

const Projects = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projets");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Projets | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO?.description} />
				<meta
					name="keywords"
					content={currentSEO?.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<div>
					<NavBar active="projets" />
					<div className="content-wrapper">
						<div className="projects-container">
							<Logo width={350} />
							<h1 className="title projects-title">Projets</h1>

							<div className="subtitle projects-subtitle">
								<p>
									Voici quelques-uns des projects sur lequels nous avons travaillés.
								</p>

								<p>
									Vous pouvez aussi voir plus de détails sur
									ma formation et mes emplois précédents sur
									mon{" "}
									<Link
										to={
											"https://www.linkedin.com/in/cindyptn"
										}
										target="_blank"
									>
										profil LinkedIn
									</Link>
									.
								</p>
							</div>

							<div className="projects-list">
								<AllProjects />
							</div>
						</div>
					</div>
				</div>
				<div className="page-footer">
					<Footer />
				</div>
			</div>
		</React.Fragment>
	);
};

export default Projects;
