import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/common/socials";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/contact.css";

const Contact = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "contact");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Contact | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO?.description} />
				<meta
					name="keywords"
					content={currentSEO?.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<div style={{ display: "block" }}>
					<NavBar active="contact" />
					<div className="content-wrapper">
						<div className="contact-container">
							<Logo width={350} />
							<h1 className="title contact-title">
								Me contacter
							</h1>

							<div className="subtitle contact-subtitle">
								Si vous avez une question ou un commentaire ou
								voulez simplement discuter, n'hésitez pas à
								m'envoyer un courriel directement à
								work@cindypotvin.com ou à me contacter sur les
								réseaux sociaux ci-dessous.
							</div>
							<div className="subtitle contact-subtitle">
								Merci pour votre intérêt et j'attends de vos
								nouvelles avec impatience !
							</div>
						</div>

						<div className="socials-container">
							<div className="contact-socials">
								<Socials />
							</div>
						</div>
					</div>
				</div>
				<div className="page-footer">
					<Footer />
				</div>
			</div>
		</React.Fragment>
	);
};

export default Contact;
