import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/projects.css";

const ServicesAutomatisation = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "services-logiciel");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Projets | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO?.description} />
				<meta
					name="keywords"
					content={currentSEO?.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<div>
					<NavBar active="services-logiciel" />
					<div className="content-wrapper">
						<div className="projects-container">
							<Logo width={350} />
							<h1 className="title projects-title">
								Services logiciels
							</h1>
							<div className="text">
								<h2 className="title">
									Création de logiciels sur mesure
								</h2>
								<p>
									Les entreprises de toutes tailles peuvent
									bénéficier de logiciels faits sur mesure pour
									améliorer leur productivité, automatiser leurs procédés et
									faire le suivi de différentes
									opérations.
								</p>
								<p>
									Voici quelques types de problèmes qui se prêtent bien
									à la création d'un logiciel sur mesure, ou
									d'un plugin à ajouter à un logiciel existant
									:
								</p>
								<ul>
									<li>Inspection et suivis d'équipement.</li>
									<li>
										Gestion d'inventaire et de processus de
										fabrication.
									</li>
									<li>
										Automatisation de processus de gestion
										(par exemple saisie manuelle d'un
										système à un autre).
									</li>
									<li>Gestion du travail des employés.</li>
									<li>Commerce en ligne.</li>
									<li>Approbation de dépenses.</li>
								</ul>
								<p className="text">
									Au Québec, le processus de R-D (Recherche et
									Développement) en général et la
									programmation informatique en particulier
									peut être admissible à des crédits
									d'impôt. Voir
									le document <Link href="https://www.revenuquebec.ca/fr/services-en-ligne/formulaires-et-publications/details-courant/in-109/">Aide fiscale pour la recherche scientifique et le développement expérimental</Link> de Revenu
									Québec pour plus de détails.
									Il existe d'autres programmes
									pour soutenir l'innovation, l'automatisation
									et la recherche; n'hésitez pas à contacter vos organismes locaux!
								</p>
								<h3 className="title">
									Quel est le processus de création d'un
									logiciel?
								</h3>
								<p>
									Avant la signature du contrat, nous aurons
									une discussion pour comprendre quels sont vos besoins et les problèmes que vous voulez régler. Nous
									discuterons aussi des technologies à utiliser,
									tel qu'une application web ou mobile ou un
									plugin pour améliorer une application que vous utiliser
									déjà.
								</p>
								<p>
									Par la suite, nous allons vous envoyer un
									devis pour approbation décrivant sommairement ce que
									l'application devra faire, les différentes
									étapes pour y arriver et nos prix.
								</p>
								<p>Ensuite, à chaque étape de réalisation, nous allons:</p>
								<ul>
									<li>
										Analyser plus en détail votre besoin
										pour en dégager les règles d'affaires et
										vous proposer des maquettes du résultat
										à obtenir.
									</li>
									<li>
										Réaliser le logiciel en utilisant les
										technologies précisées au devis.
									</li>
									<li>
										Faire une démonstration de ce qui a été réalisé
										et le déployer pour que vous puissiez
										enfin l'utiliser.
									</li>
								</ul>
								<p>
									Lorsque le projet sera complété, de
									nouvelles fonctionnalités peuvent être
									ajoutées par la suite avec un nouveau devis.
								</p>
								<p>Vous voulez en savoir plus sur la création d'un logiciel sur mesure pour votre entreprise? N'hésitez pas à <Link to="/contact">nous contacter</Link> pour discuter avec nous de ce qui est possible!</p>
								<h2 className="title">
									Maintenance de logiciels existants
								</h2>
								<p>
									Nous pouvons aussi prendre en charge une
									application déjà existante construite par un
									tiers afin de la mettre à jour, de corriger
									des problèmes ou d'ajouter de nouvelles
									fonctionnalités.
								</p>
								<p>
									Nous pouvons prendre l'application en charge
									avec un forfait de maintenance trimestriel
									pour des mises à jour régulières et de la
									correction de bogues, ou des modifications à la demande.
								</p>
								<p>
									Nous avons de l'expérience dans un grand
									nombre de technologies; n'hésitez pas à <Link to="/contact">nous contacter</Link> pour plus d'information.
								</p>
							</div>

							<div className="projects-list">
								<AllProjects projects={INFO.projetsLogiciel} />
							</div>
						</div>
					</div>
				</div>
				<div className="page-footer">
					<Footer />
				</div>
			</div>
		</React.Fragment>
	);
};

export default ServicesAutomatisation;
