const INFO = {
	main: {
		title: "Technologies Cindy Potvin - Atelier de développement logiciel et automatisation",
		name: "Cindy Potvin",
		email: "work@cindypotvin.com",
		logo: "../logo.svg",
	},

	socials: {
		twitter: "https://twitter.com/CindyPtn",
		github: "https://github.com/CindyPotvin",
		linkedin: "https://www.linkedin.com/company/cptn-tech/",
	},

	articles: {
		title: "Articles",
		description:
			"J'écris occasionnellement des articles sur mes projets les plus intéressants et sur différents sujets techniques. Il y a plus de contenu sur mon blog en anglais à https://cindypotvin.com.",
	},

	projetsLogiciel: [
		{
			title: "Frontend pour Ark Light security",
			description:
				"Ajout de fonctionnalités à leurs applications client et interne en React et ajout de tests automatisés Playwright et de tests d'intégration.",
			logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
			links: [
				{
					linkText: "Voir le site web d'Ark Light",
					link: "https://www.arklightsecurity.com",
				},
			],
		},
		{
			title: "Application Android HUDU pipeline",
			description:
				"Réécriture en Android d'une application iOS utilisée pour l'entrée de données d'inspection.",
			logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/android/android-plain.svg",
			links: [
				{
					linkText: "Voir sur Google Play",
					link: "https://play.google.com/store/apps/details?id=ca.hudu&hl=en_US",
				},
			],
		},
		{
			title: "Calculatrice de tissage",
			description:
				"Application gratuite codée avec React Native/Expo pour expérimenter avec cette plateforme.",
			logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
			links: [
				{
					linkText: "Voir sur Google Play",
					link: "https://play.google.com/store/apps/details?id=com.cindypotvin.weavingcalculator&hl=fr",
				},
				{
					linkText: "Voir sur Apple App Store",
					link: "https://apps.apple.com/ci/app/calculatrice-de-tissage/id1605647678",
				},
			],
		},
	],
	projetsAutomatisation: [
		{
			title: "Circuit pour brasseur de lait",
			description:
				"Circuit de contrôle avec des relais pour un brasseur de lait basé avec la plateforme Arduino",
			logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/arduino/arduino-original.svg",
			links: [
				{ linkText: "Voir l'étude de cas", link: "/article/1" },
			],
		}
	],
};

export default INFO;
